import React from "react";
import { Link } from "react-router-dom";

function ErrorWrapper() {
  return (
    <>
      <section className="back-to-home sec-pad">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="error-wrapper">
                <img
                  src={process.env.PUBLIC_URL + "/img/404.png"}
                  alt="images"
                />
                <h3>Oops! This Page Could Not Be Found</h3>
                <p>
                  SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAVE
                  BEEN DEMOVED NAME CHANGED OR IS TEMPORARILY UNAVAILABLE
                </p>
                <div className="home-btn">
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    onClick={window.scrollTo({ top: 0, behavior: "smooth" })}
                  >
                    <i className="bi bi-house-door" />
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ErrorWrapper;
