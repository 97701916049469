import React from "react";
import { Link } from "react-router-dom";

function PostTagWidget() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="sidebar-widget">
        <h4>Post Tag</h4>
        <ul className="tag-list">
          <li>
            <Link>Website</Link>
          </li>
          <li>
            <Link>Web Design</Link>
          </li>
          <li>
            <Link>Development</Link>
          </li>
          <li>
            <Link>Graphic Design</Link>
          </li>
          <li>
            <Link>Research</Link>
          </li>
          <li>
            <Link>UI/UX Design</Link>
          </li>
          <li>
            <Link>Game</Link>
          </li>
          <li>
            <Link>AR/VR/MR</Link>
          </li>
          <li>
            <Link>Software</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default PostTagWidget;
