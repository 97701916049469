import React from "react";
import { Link } from "react-router-dom";
import blog from "./Blog";
import { useLocation } from "react-router-dom";


function BlogDetailsContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const location = useLocation();
  const index = new URLSearchParams(location.search).get("index");
  const blogItem = blog[index];

  if (!blogItem) {
    return <p>Blog item not found</p>;
  }
  
  return (
    <>
      <div className="blog-details-content">
        <h3>{blogItem.head1}</h3>
        <div className="author-date layout2">
          <Link >{blogItem.link1}</Link>
          <Link >{blogItem.link2}</Link>
          <Link >{blogItem.link3}</Link>
        </div>
        <div className="details-thumb">
          <img src={blogItem.img1} alt="images" height={470} />
        </div>
        <p>{blogItem.paragraph1}</p>
        <div className="blog-gallery">
          <div className="b-gallery">
            <img src={blogItem.img2} alt="images"  height={250}/>
          </div>
          <div className="b-gallery">
            <img src={blogItem.img3} alt="images" height={250}/>
          </div>
        </div>
        <h4>{blogItem.head2}</h4>
        <div className="special">
          <p>{blogItem.paragraph2}</p>
        </div>
        <p>{blogItem.paragraph3}</p>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>{blogItem.paragraph4} </p>
        </div>
        <p>{blogItem.paragraph5} </p>
      </div>
    </>
  );
}

export default BlogDetailsContent;
