import React from "react";
import CountUp from "react-countup";
import completed from "../../../assets/img/icons/completed-task.webp";
import rating from "../../../assets/img/icons/rating.webp";
import team from "../../../assets/img/icons/team.webp";
import time from "../../../assets/img/icons/time.webp";




function FeaturesArea() {
  return (
    <>
      <section className="features-area">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title white">
              <span>Our Achievemments</span>
              <h2>Features</h2>
              <p style={{color: "green"}}>
                Timely and proactive responses to customer inquiries demonstrate
                dedication to solving their issues promptly. Good customer
                service always starts with a human touch.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                  {/* <i
                      className="fas fa-handshake"
                      style={{ fontSize: "60px",  color: "black", backgroundColor: "#e16a32" ,borderRadius:"50%" }}
                    /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL + completed
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={150} delay={3} duration={5} /> <sup>+</sup>
                  <h4 style={{color: "green"}}>Projects Completed</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                  {/* <i
                      className="fas fa-star"
                      style={{ fontSize: "60px", backgroundColor: "#e16a32" ,borderRadius:"50%" }}
                    /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL + rating
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={50} delay={3} duration={5} /> <sup>+</sup>
                  <h4 style={{color: "green"}}>Satisfied Clients</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    {/* <i
                      className="fa fa-users"
                      style={{ fontSize: "60px", backgroundColor: "#e16a32" ,borderRadius:"50%" }}
                    /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL + team
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={20} delay={3} duration={5} /> <sup>+</sup>
                  <h4 style={{color: "green"}}>Experts In Our Team</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    {/* <i
                      className="fas fa-shipping-fast"
                      style={{ fontSize: "60px", backgroundColor: "#e16a32",borderRadius:"50%" }}
                    /> */}

                    <img
                      src={
                        process.env.PUBLIC_URL + time
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={100} delay={3} duration={5} /> <sup>%</sup>
                  <h4 style={{color: "green"}}>On Time Delivery</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturesArea;
