import React from "react";
import { Link } from "react-router-dom";
import software_blog from "../../../assets/img/blog/blog-software.webp";
import uiux_blog from "../../../assets/img/blog/blog-uiux.webp";
import game_blog from "../../../assets/img/blog/blog-game.webp";

function LatesPost() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <section className="blog-area">
        <div className="container">
          <div
            className="title-wrap -6 wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title">
              <span>Blog Page</span>
              <h2>Don’t Forget to check our Blogpost!</h2>
              <p>
              Stay ahead of the curve with our IT blog. Get the latest insights, industry trends, and expert advice on everything from cybersecurity to cloud solutions.</p>
            </div>
          </div>
          <div className="row gy-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link
                    to={`${process.env.PUBLIC_URL}/blog-details`}
                    onClick={scrollTop}
                  >
                    <img
                      src={process.env.PUBLIC_URL + uiux_blog}
                      alt="latest-post-img"
                      height={300}
                    />
                  </Link>
                  <div className="tag">
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      UI/UX
                    </Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link >By, Admin</Link>
                    <Link >7.8.2020</Link>
                  </div>
                  <h4>
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog-details?index=1`}
                      onClick={scrollTop}
                    >
                      Explore the principles of visual design and their role{" "}
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link
                    to={`${process.env.PUBLIC_URL}/blog-details`}
                    onClick={scrollTop}
                  >
                    <img
                      src={process.env.PUBLIC_URL + software_blog}
                      alt="latest-post-img"
                      height={300}
                    />
                  </Link>
                  <div className="tag">
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Software
                    </Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link >By, Admin</Link>
                    <Link >10.1.2021</Link>
                  </div>
                  <h4>
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog-details/?index=0`}
                      onClick={scrollTop}
                    >
                      In today's rapidly evolving digital landscape
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow animated fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link
                    to={`${process.env.PUBLIC_URL}/blog-details`}
                    onClick={scrollTop}
                  >
                    <img
                      src={process.env.PUBLIC_URL + game_blog}
                      alt="latest-post-img"
                      height={300}
                    />
                  </Link>
                  <div className="tag">
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Gaming
                    </Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link >By, Admin</Link>
                    <Link >30.10.2022</Link>
                  </div>
                  <h4>
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog-details/?index=3`}
                      onClick={scrollTop}
                    >
                      Explore the stages of the game development lifecycle
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LatesPost;
