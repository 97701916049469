import React from "react";
import { Link } from "react-router-dom";

function CatagoryWidget() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="sidebar-widget">
        <h4>Category</h4>
        <ul className="category">
          <li>
            <Link>
              Website Development
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
          <li>
            <Link>
              Mobile App Development
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
          <li>
            <Link>
              Software Development
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
          <li>
            <Link>
              Digital Marketing
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
          <li>
            <Link>
              UI/UX Design
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
          <li>
            <Link>
              Cybersecurity Solutions
              <i className="bi bi-arrow-right" />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default CatagoryWidget;
