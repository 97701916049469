import React from "react";
import { Link } from "react-router-dom";

function SingleBlog(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  console.log("Service Number:", props.serviceNumber);

  return (
    <>
      <div className="col-md-6">
        <div className="single-blog">
          <div className="blog-thumb">
            <Link
              to={`${process.env.PUBLIC_URL}/blog-details`}
              onClick={scrollTop}
            >
              <img
                src={`${process.env.PUBLIC_URL} ${props.image}`}
                height={300}
                alt="images"
              />
            </Link>
            <div className="tag">
              <Link
                to={`${process.env.PUBLIC_URL}/project`}
                onClick={scrollTop}
              >
                {" "}
                <span>{props.serviceNumber}</span>.{props.title}{" "}
              </Link>
            </div>
          </div>
          <div className="blog-inner">
            <div className="author-date">
              <Link >By, Admin</Link>
              <Link >{props.date}</Link>
            </div>
            {/* <h4>
              <Link
                to={`${process.env.PUBLIC_URL}/blog-details`}
                onClick={scrollTop}
              ></Link>
            </h4> */}
            <h4>
              {props.serviceNumber && (
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/blog-details`,
                    search: `?index=${props.serviceNumber - 1}`,
                  }}
                  onClick={scrollTop}
                >
                  {props.details}
                </Link>
              )}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleBlog;
