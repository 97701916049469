import uiux_1 from "../assets/img/project/project-ui-ux_1.webp";
import uiux_2 from "../assets/img/project/project-ui-ux_2.webp";
import web_1 from "../assets/img/project/project-web-1.webp";
import web_2 from "../assets/img/project/project-web-2.webp";
import mobile_1 from "../assets/img/project/project-mobile-1.webp";
import mobile_2 from "../assets/img/project/project-mobile-2.webp";
import digital_1 from "../assets/img/project/project-digital-1.webp";
import digital_2 from "../assets/img/project/project-digital-2.webp";

const data = [
  {
    id: 1,
    title: "Consulting & Research",
    image: uiux_1,
    category: "UI",
    details: "Aarvi Technology",
    detailImage: uiux_1,
    projectName: "UI/UX",
    heading: "IT Company",
    paragraph1:
      "Welcome to Aarvi Technology, where innovation meets expertise in the digital realm. As a premier provider of IT solutions, we pride ourselves on delivering cutting-edge technology tailored to meet the unique needs of businesses worldwide. From web development to cybersecurity, cloud computing to artificial intelligence, our team of skilled professionals is dedicated to harnessing the power of technology to drive your success. With a proven track record of delivering exceptional results, we combine creativity with technical proficiency to deliver solutions that exceed expectations. At Aarvi Technology, we understand that every business is different, which is why we offer customized services designed to address your specific challenges and objectives. Partner with us and unlock the full potential of your digital presence. Welcome to the future of technology with Aarvi Technology.",
    heading2: "Client Needs",
    paragraph2:
      "Ensure that the navigation menu is intuitive and easy to use. Organize the menu logically, categorizing services, products, about us, blog, contact, etc., making it effortless for users to find what they're looking for.",
    paragraph3:
      "Make sure the website is fully responsive and displays correctly on all devices, including desktops, laptops, tablets, and smartphones. Test the website across various screen sizes to ensure a seamless experience for users.",
    paragraph4:
      "Provide clear and concise content that effectively communicates the company's services, expertise, and value proposition. Use engaging copywriting to capture users' attention and encourage them to explore further.",
    clientName: "Kishan Shingala",
    company: "Aarvi Technology",
    location: "404, Angel business center, Surat",
    projecttype: "It Company",
    duration: "1 month",
  },
  {
    id: 2,
    title: "Security Solutions",
    image: mobile_1,
    category: "developing",
    details: "Genesis Gifts",
    detailImage: mobile_1,
    projectName: "Mobile",
    heading: " Corporate Gifting",
    paragraph1:
      "Welcome to Genesis Gifts, your premier destination for all corporate and promotional gift needs in the UAE. At Genesis Gifts, we specialize in providing high-quality, customizable gifts that leave a lasting impression. Our extensive catalog features a wide range of products, from elegant executive gifts and tech gadgets to eco-friendly items and branded merchandise, ensuring that you find the perfect gift for any occasion. We pride ourselves on our attention to detail and commitment to excellence, offering personalized service to meet the unique requirements of each client. Whether you need bulk orders for a corporate event or bespoke items for special occasions, our team is dedicated to delivering exceptional value and customer satisfaction. With a user-friendly website and a seamless shopping experience, you can easily browse, customize, and order gifts from the comfort of your office or home. Explore our collections, take advantage of our competitive pricing, and discover why Genesis Gifts is the trusted choice for corporate gifting in the UAE. Join our growing list of satisfied clients and elevate your gifting strategy with Genesis Gifts today.",
    heading2: "Client Needs",
    paragraph2:
      "Highlighting special promotions, new arrivals, and bestsellers,Prominent and easy-to-use search functionality.",
    paragraph3:
      "Easy sign-up process,Manage personal information, addresses, and payment methods, View past orders and their statuses.",
    paragraph4:
      "Ensure the website is fully functional and aesthetically pleasing on mobile devices.",
    clientName: "Kishore Mehta",
    company: "Genesis Gifts",
    location: "Office 2005, Aspect Tower Business Bay, Dubai",
    projecttype: "Corporate Gifting",
    duration: "5 month",
  },
  {
    id: 3,
    title: "Consulting & Research",
    image: uiux_2,
    category: "UI",
    details: "Bhavani Diamonds",
    detailImage: uiux_2,
    projectName: "UI/UX",
    heading: "Jewelry & Watches Company",
    paragraph1:
      "Welcome to Bhavani Diamonds , where brilliance meets sustainability in the world of luxury. We specialize in crafting exquisite lab-grown diamonds that rival their natural counterparts in beauty and quality. Our commitment to innovation and ethical practices ensures that each diamond is not only stunning but also environmentally friendly and conflict-free. With cutting-edge technology and expert craftsmanship, we offer a dazzling array of diamonds in various cuts, colors, and carat weights to suit every taste and occasion. Whether you're searching for the perfect engagement ring, a statement piece of jewelry, or a thoughtful gift, our collection is sure to captivate. Discover the allure of lab-grown diamonds and join us in redefining the future of fine jewelry. Experience the brilliance without compromise at Bhavani Diamonds.",
    heading2: "Client Needs",
    paragraph2:
      "The design should reflect the luxury and sophistication associated with diamonds. Use a minimalist approach with ample white space to highlight the diamonds and avoid clutter.",
    paragraph3:
      "Utilize high-resolution images and videos to showcase the brilliance and intricacies of the diamonds. Implement zoom functionality to allow users to examine the diamonds in detail.",
    paragraph4:
      "Ensure that the website is fully responsive and optimized for various devices and screen sizes. Test the website extensively to ensure consistency and usability across desktops, tablets, and smartphones.",
    clientName: "Bhavin Mangroliya",
    company: "Bhavani Diamonds",
    location: "DC 3100, Bharat Diamond Bourse, Mumbai",
    projecttype: "Jewelry & Watches Company",
    duration: "1.5 month",
  },
  {
    id: 4,
    title: "Web Design & Software Development",
    image: web_1,
    category: "web",
    details: " Agriculture Company",
    detailImage: web_1,
    projectName: "Website Design",
    heading: "Agriculture",
    paragraph1:
      "Welcome to Swarnim Agriculture, where innovation and tradition unite to cultivate a sustainable future. As leaders in modern agriculture, we are dedicated to providing high-quality products and services that enhance productivity and promote environmental stewardship. Our commitment to cutting-edge research and advanced farming techniques ensures that we deliver the best in crop cultivation, livestock management, and agricultural technology. From precision farming to organic practices, we offer solutions tailored to meet the diverse needs of farmers and consumers alike. At [Your Agriculture Company], we believe in nurturing the land responsibly to produce nutritious and safe food for communities worldwide. Join us in our mission to create a greener, healthier planet through sustainable agriculture. Explore our range of services and products designed to help you grow better, live better, and thrive.",
    heading2: "Client Needs",
    paragraph2:
      "Incorporate a color palette inspired by nature, such as greens, browns, and earth tones, to reflect the agricultural theme.",
    paragraph3:
      "Include quick links to important sections like Product Catalog, Latest News, and Contact Information.",
    paragraph4:
      "Create detailed product pages with high-quality images, descriptions, benefits, and usage instructions.",
    clientName: "Nitin Bhalani",
    company: "Swarnim Agriculture",
    location: "Block No.: 47, D-2/A, Ekta Industries, Pipodara",
    projecttype: "Agriculture Company",
    duration: "20 days",
  },
  {
    id: 5,
    title: "Digital & Immersive Experiences",
    image: digital_1,
    category: "digital",
    details: "Regent Nx Chairs",
    detailImage: digital_1,
    projectName: "Digital Marketing",
    heading: "Chairs",
    paragraph1:
      "Welcome to Regent Nx Chairs, where luxury meets ergonomics to redefine your seating experience. Our meticulously crafted chairs blend cutting-edge design with unparalleled comfort, making them the perfect choice for your office or home. At Regent Nx Chairs, we understand the importance of quality and style, which is why each chair is engineered with the finest materials and innovative technology. Our diverse collection caters to all preferences, ensuring that you find the ideal chair that complements your aesthetic and functional needs. With a commitment to excellence and customer satisfaction, Regent Nx Chairs offers a seamless shopping experience from selection to delivery. Stay ahead in comfort and sophistication by choosing Regent Nx Chairs, where your seating comfort is our priority. Explore our website today and discover the perfect blend of elegance and functionality for your workspace. Follow us on social media for the latest updates, exclusive offers, and inspiration for your seating solutions.",
    heading2: "Client Needs",
    paragraph2:
      "Post high-resolution images of your chairs in various settings (home, office, outdoor). Show different angles and details.",
    paragraph3:
      " Create short, engaging videos showcasing your products, behind-the-scenes footage, and how-to guides. Reels can be used to showcase product features or customer testimonials.",
    paragraph4:
      "Use Instagram Insights to track the performance of your posts, stories, and ads. Pay attention to metrics like engagement rate, reach, impressions, and follower growth.",
    clientName: "Shakir Hussain",
    company: "Regent Nx Chairs",
    location: "250/A Wadia Building, Mumbai",
    projecttype: "Chair Company",
    duration: "5 days",
  },
  {
    id: 6,
    title: "Web Design & Software Development",
    image: web_2,
    category: "web",
    details: "Om Textile",
    detailImage: web_2,
    projectName: "Website Design",
    heading: "Textile",
    paragraph1:
      "Welcome to Om Textiles, where craftsmanship and innovation weave together to create exceptional fabrics and textiles. Our passion for quality and design drives us to produce a diverse range of textiles that cater to various industries, from fashion to home decor and beyond. With a commitment to sustainability, we incorporate eco-friendly materials and processes to ensure our products are not only beautiful but also environmentally responsible. Our expert team of designers and artisans works tirelessly to bring you the finest fabrics, characterized by their durability, elegance, and versatility. Whether you are looking for luxurious silks, sturdy cottons, or cutting-edge technical fabrics, Om Textiles has something to inspire every creator. Join us in celebrating the art of textiles and discover how our products can enhance your projects and elevate your brand. Experience the perfect blend of tradition and modernity with Om Textiles.",
    heading2: "Client Needs",
    paragraph2:
      "Create a dedicated page or section titled Our Facilities.",
    paragraph3:
      " Use high-resolution images of the machines and facilities. Each subsection should include detailed descriptions of the processes, highlighting the machinery and technology used.",
    paragraph4:
      "Display logos of certifications prominently with brief descriptions of each certification and its significance.",
    clientName: "Bharat kathiriya",
    company: "Om Textiles",
    location: "2/647 B, Venus Garden, Tamilnadu",
    projecttype: "Textile Company",
    duration: "14 days",
  },
  {
    id: 7,
    title: "Digital & Immersive Experiences",
    image: digital_2,
    category: "digital",
    details: "Prince Lottery",
    detailImage: digital_2,
    projectName: "Digital Marketing",
    heading: "Lottery Ticket Agents",
    paragraph1:
      "Welcome to Regent Nx Chairs, where luxury meets ergonomics to redefine your seating experience. Our meticulously crafted chairs blend cutting-edge design with unparalleled comfort, making them the perfect choice for your office or home. At Regent Nx Chairs, we understand the importance of quality and style, which is why each chair is engineered with the finest materials and innovative technology. Our diverse collection caters to all preferences, ensuring that you find the ideal chair that complements your aesthetic and functional needs. With a commitment to excellence and customer satisfaction, Regent Nx Chairs offers a seamless shopping experience from selection to delivery. Stay ahead in comfort and sophistication by choosing Regent Nx Chairs, where your seating comfort is our priority. Explore our website today and discover the perfect blend of elegance and functionality for your workspace. Follow us on social media for the latest updates, exclusive offers, and inspiration for your seating solutions.",
    heading2: "Client Needs",
    paragraph2:
      "Regularly post about winners with their consent (either with their photo or anonymized with initials). Use celebratory graphics and images.",
    paragraph3:
      "Use Instagram Insights to track engagement, reach, and follower growth. Analyze which types of content perform best.",
    paragraph4:
      "Ensure your contact details are easily accessible through your profile, Share positive testimonials from satisfied customers in your stories or posts.",
    clientName: "Prince",
    company: "Prince Lottery",
    location: "221-A, Abdul Rehman Street, Mumbai",
    projecttype: "Lottery Ticket Company",
    duration: "3.5 days",
  },
  {
    id: 8,
    title: "Security Solutions",
    image: mobile_2,
    category: "developing",
    details: "SuperSnap Delivery",
    detailImage: mobile_2,
    projectName: "Mobile",
    heading: " Hyperlocal Delivery",
    paragraph1:
      "Welcome to Hyperlocal Delivery, your go-to solution for fast, reliable, and efficient delivery services in your local area. At Hyperlocal Delivery, we specialize in connecting businesses and customers with a network of professional couriers who ensure your packages are delivered swiftly and safely. Our comprehensive services include UX research to understand and cater to user needs, service design to optimize every step of the delivery process, and UI re-design to create an intuitive and seamless user experience. Additionally, our expert app development team crafts robust and user-friendly mobile applications that keep you connected and informed every step of the way. Whether you’re a small business looking to enhance your delivery capabilities or a customer in need of quick and reliable service, Hyperlocal Delivery is here to meet your needs. Explore our innovative solutions and discover how we can streamline your delivery processes, making life easier for you and your customers. Join the Hyperlocal Delivery community today and experience the future of local delivery services.",
    heading2: "Client Needs",
    paragraph2:
      "Simple process to book a delivery service,Live tracking of delivery status.",
    paragraph3:
      "Easy-to-use interface with smooth navigation, Notifications for order status changes and delivery updates.",
    paragraph4:
      "Multiple payment options (credit card, PayPal, etc.),Ensure the website is fully functional and aesthetically pleasing on mobile devices.",
    clientName: "Rishi Thakur",
    company: "SuperSnap Delivery",
    location: "B 3 & 4 590 Off W. E. Highway, D2, City, Mumbai",
    projecttype: " Hyperlocal Delivery",
    duration: "7 month",
  },
 
];

export default data;
