import React, { useState } from "react";
import axios from "axios";


function SubscribArea() {
  const [email, setEmail]=  useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  
  const handleSubmit = async (e) => { 
    e.preventDefault();

    if (!email) {
      setErrorMessage('Email field cannot be empty.');
      return;
    }

    const serviceId = 'service_lcx2akm';
    const templateId = 'template_oogsj8l';
    const publickey =  'LmEXJVaZ52abndk_0';

  const data = {

    service_id: serviceId,
    template_id: templateId,
    user_id: publickey,
    template_params: {
    from_email: email,
    to_name: 'tiranga infotech',
    }
  }

  try {
    const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
     console.log(res.data);
     setSuccessMessage('Mail has been successfully registered for news letter subscription');
    setEmail('');
    setErrorMessage('');
  } catch (error) {
    console.error(error);
    setErrorMessage('Failed to send email. Please try again later.');
    }
}
  return (
    <>
     <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div className="col-lg-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-cnt">
                  <span>Don't Miss Out</span>
                  <h3>Subscribe To Our</h3>
                  <strong >Newsletter</strong>
                </div>
              </div>
              <div className="col-lg-6 wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-form">
                  <form onSubmit={handleSubmit}>
                    <input type="email" name="email" placeholder="Type Your Email"  value={email}   onChange={(e) => setEmail(e.target.value)} />
                    <input type="submit"  />
                    {successMessage && <div className="success-message" style={{ color: "white" }}>{successMessage}</div>}
                    {errorMessage && <div className="error-message" style={{ color: "red" }}>{errorMessage}</div>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default SubscribArea