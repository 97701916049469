import React from "react";
import history_1 from "../../../assets/img/history-1.jpeg";
import history_2 from "../../../assets/img/history-2.jpg";
import history_3 from "../../../assets/img/history-3.jpg";
import history_4 from "../../../assets/img/history-4.jpg";

function HistoryArea() {
  return (
    <>
      <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>History of Tiranga Infotech</h2>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + history_1}
                  alt="history-img" height={300}
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2020</span>
                  <h4>We opened our office</h4>
                  <p>
                    Our story begins with the opening of our office doors,
                    marking the dawn of our commitment to innovation and
                    excellence in the IT realm. With passion as our compass and
                    expertise as our guide, we embraced the challenge of
                    reshaping the digital landscape. Each day, our dedicated
                    team strides forward, fueled by the vision of empowering
                    businesses through transformative technology solutions.
                    Since that pivotal moment, we've fostered a culture of
                    collaboration, creativity, and continuous growth, setting
                    the stage for impactful partnerships and remarkable
                    achievements.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2021</span>
                  <h4>We hustle for design that makes you shine</h4>
                  <p>
                    Our journey traces back to our relentless pursuit of design
                    excellence, where each pixel, line of code, and creative
                    concept is meticulously crafted. With unwavering dedication,
                    we immerse ourselves in the art of design, tirelessly
                    striving to push boundaries and deliver solutions that
                    inspire. From the earliest sketches to the final polished
                    product, our team's passion fuels every aspect of our work,
                    ensuring that each design reflects our commitment to
                    innovation and client success. It's not just about creating
                    visually stunning experiences; it's about crafting solutions
                    that resonate, engage, and elevate.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + history_2}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + history_3}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>Priority on success rate and wins</h4>
                  <p>
                    Our journey is a testament to perseverance, innovation, and
                    unwavering dedication. From our inception, we've embraced
                    challenges as opportunities, turning aspirations into
                    achievements. Through strategic vision and relentless
                    effort, we've triumphed in the ever-evolving tech landscape,
                    shaping our legacy one milestone at a time. Our success
                    isn't just measured in victories, but in the lasting impact
                    we've made on clients and industries alike. Together, we
                    celebrate our wins and anticipate the boundless
                    possibilities that lie ahead on our journey of continuous
                    growth and triumph.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2023</span>
                  <h4> Company Culture</h4>
                  <p>
                    The company culture fosters innovation through open
                    communication and collaboration, emphasizing creativity and
                    flexibility in problem-solving. Employees are empowered to
                    take ownership of their projects and are encouraged to
                    pursue personal and professional growth through continuous
                    learning opportunities. Diversity and inclusion are
                    celebrated, creating a dynamic and inclusive work
                    environment where everyone's unique perspectives are valued.
                    Transparency and integrity are core values, ensuring honesty
                    and accountability at all levels of the organization.
                    Overall, the company culture prioritizes employee
                    well-being, fostering a supportive and vibrant community
                    dedicated to driving success together.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + history_4}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoryArea;
