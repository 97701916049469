import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";

function TermsOfUse() {
  return (
    <>
      <AnimateLine />
      <Breadcrumb pageName="Terms & Condition" pageTitle="Terms & Condition" />

      <section className="faqs-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div class="policy-page">
              <div class="container">
                <div class="col-lg-8">
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Effective Date: 10 Nov 2023
                    </button>
                    <p>
                      These Terms and Conditions ("Terms") govern your use of
                      the products and services provided by Tiranga Infotech. By
                      using our services, you agree to comply with these Terms.
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Use of Our Services
                    </button>
                    <p>
                      You may use our services only as permitted by these Terms
                      and applicable laws. You are responsible for ensuring that
                      your use of our services does not violate any applicable
                      laws or regulations.
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Intellectual Property
                    </button>
                    <p>
                      All content on our website and services is protected by
                      intellectual property rights. You may not use our content
                      without our permission.
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Limitation of Liability
                    </button>
                    <p>
                      To the extent permitted by law, Tiranga Infotech shall not
                      be liable for any indirect, incidental, special,
                      consequential, or punitive damages, or any loss of profits
                      or revenues, whether incurred directly or indirectly.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Termination
                    </button>
                    <p>
                      We may suspend or terminate your access to our services if
                      you violate these Terms or applicable laws.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Changes to Terms
                    </button>
                    <p>
                      We may revise these Terms from time to time. The most
                      current version will be posted on our website.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscribArea />
    </>
  );
}

export default TermsOfUse;
