import React from "react";
import BannerWidget from "./BannerWidget";
import CatagoryWidget from "./CatagoryWidget";
import NewPostWidget from "./NewPostWidget";
import PostTagWidget from "./PostTagWidget";
import SearchWidget from "./SearchWidget";
import SingleBlog from "./SingleBlog";
import blog from "../BlogDetails/Blog";

function BlogSidebarWrapper() {
  return (
    <>
      <div className="blog-grid sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {/* <SearchWidget /> */}
              <CatagoryWidget />
              <NewPostWidget />
              <PostTagWidget />
              <BannerWidget />
            </div>
            <div className="col-lg-8">
              <div className="blog-item-grid">
                <div className="row g-4">
                  {blog.map((item, index) => (
                    <SingleBlog
                      serviceNumber={item.num}
                      image={item.imag}
                      date={item.date}
                      title={item.title}
                      details={item.details}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogSidebarWrapper;
