import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";
import service from "./Service";
import SubscribArea from "../../common/SubscribArea";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation } from "react-router-dom";
import widget from "../../../assets/img/widget-banner-bg.jpg";
 

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const location = useLocation();
  const index = new URLSearchParams(location.search).get("index");
// Add a check for index validity
if (index === null || index === undefined || isNaN(index) || index < 0 || index >= service.length) {
  // Handle case where index is invalid or out of bounds
  return <div>Service details not found</div>;
}

const serviceItem = service[index];

return (
    <>
      <AnimateLine />
      <Breadcrumb pageName="Service Details" pageTitle="Service Details" />
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src={serviceItem.img} alt="images service" height={400} />
                <h3>
                  <i>
                    <img
                      src={serviceItem.icon}
                      alt="service icon images"
                      height={70}
                    />
                  </i>
                  {serviceItem.title}
                </h3>
                <p>{serviceItem.paragraph1}</p>
                <p>{serviceItem.paragraph2}</p>
                <p>{serviceItem.paragraph3}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Website Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Mobile App Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Software Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Digital Marketing
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      UI/UX Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cybersecurity Solutions
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + widget}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:447404950269">+44-74049 50269</a>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems />
          <SubscribArea />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
