import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import about_baner_1 from "../../../assets/img/about-baner_1.webp";
import about_baner_2 from "../../../assets/img/about-baner_2.webp";
import Logo_Tiranga from "../../../assets/img/logo.png";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 or-2 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>
                  We listen, we create, we launch
                </h3>
                <p>
                Your software, your way. We partner with you to design, code, and develop innovative solutions that drive results. From idea to launch, we make your vision a reality. </p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    <img
                      src={process.env.PUBLIC_URL + Logo_Tiranga}
                      alt="about-images" 
                    />
                  </div>
                  <strong>#1</strong>
                  <h4>
                    Best Creative IT Agency And Solutions{" "}
                    <span>Since 2020</span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 or-1 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about-right">
                <div className="banner-1">
                  <img
                    src={process.env.PUBLIC_URL + about_baner_1}
                    alt="about-images"
                  />
                </div>
                <div className="banner-2">
                  <img
                    src={process.env.PUBLIC_URL + about_baner_2}
                    alt="about-images"
                  />
                  <div className="banner2-inner">
                    <div className="play">
                      <div
                        className="video-popup"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fas fa-play" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="tUP5S4YdEJo"
          autoplay={1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutArea;
