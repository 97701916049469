import React from "react";
import AnimateLine from "../../common/AnimateLine";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "../project/ProjectWrap";
import AboutArea from "./AboutArea";
import FeaturesArea from "./FeaturesArea";
import HeroArea from "./HeroArea";
import LatesPost from "./LatesPost";
import PartnarArea from "./PartnarArea";
import ServiceArea from "./ServiceArea";
import Testimonial from "./Testimonial";

function HomePageOne(props) {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <HeroArea />
        <ServiceArea />
        <AboutArea />
        <FeaturesArea />
        <ProjectWrap />
        <PartnarArea />
        <Testimonial />
        <LatesPost />
        <SubscribArea />
      </div>
    </>
  );
}

export default HomePageOne;
