import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";

function PrivacyPolicy() {
  return (
    <>
      <AnimateLine />
      <Breadcrumb pageName="Privacy Policy" pageTitle="Privacy Policy" />

      <section className="faqs-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="policy-page">
              <div className="container">
                <div className="col-lg-8">
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Effective Date: 10 Nov 2023
                    </button>
                    <p>
                      This Privacy Policy describes how Tiranga Infotech ("we,"
                      "us," or "our") collects, uses, discloses, and protects
                      personal information that we may collect through our
                      website, products, and services.
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Information We Collect
                    </button>
                    <p>
                      We may collect the following types of personal
                      information:
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Contact Information:
                    </button>
                    <p>
                      Name, email address, phone number, and postal address.
                    </p>
                  </span>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Usage Data:
                    </button>
                    <p>
                      Information about your interactions with our website and
                      services.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Device Information:
                    </button>
                    <p>
                      Information about the device you use to access our
                      services.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Cookies:
                    </button>
                    <p>
                      Information collected through cookies and similar tracking
                      technologies.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      How We Use Your Information
                    </button>
                    <p>
                      We may use your personal information for the following
                      purposes:
                    </p>
                  </span>
                  <ul  style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>To provide our services and fulfill your requests.</li>
                    <li>To improve and personalize your experience.</li>
                    <li>To communicate with you, including marketing communications.</li>
                    <li>To analyze usage and trends to improve our services.</li>
                  </ul>

                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Information Sharing
                    </button>
                    <p>
                      We do not sell or share your personal information with
                      third parties except as described in this policy.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Your Choices
                    </button>
                    <p>
                      You can exercise certain rights regarding your personal
                      information, including access and deletion.
                    </p>
                  </span>
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button">
                      Security
                    </button>
                    <p>
                      We take appropriate measures to protect personal
                      information.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscribArea />
    </>
  );
}

export default PrivacyPolicy;
