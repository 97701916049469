import React from "react";
import data from "../../../data/data";
import { useLocation } from "react-router-dom";


function SidebarWidget() {

  const location = useLocation();
  const index = new URLSearchParams(location.search).get("index");
  const projectItem = index !== null ? data[index] : null;

  if (!projectItem) {
    console.error(`Project item not found for index: ${index}`);
    return <p>Project item not found</p>;
  }
  

  return (
    <>
      <div className="sidebar-widget">
        <div className="client-box">
          <span>Client:</span>
          <h5>{projectItem.clientName}</h5>
        </div>
        <div className="client-box">
          <span>Company:</span>
          <h5>{projectItem.company}</h5>
        </div>
        <div className="client-box">
          <span>Location:</span>
          <h5>{projectItem.location}</h5>
        </div>
        <div className="client-box">
          <span>Project Type:</span>
          <h5>{projectItem.projecttype}</h5>
        </div>
        <div className="client-box">
          <span>Duration:</span>
          <h5>{projectItem.duration}</h5>
        </div>
      </div>
      <div className="sidebar-widget">
        <div className="contact-info">
          <h3>Need help?</h3>
          <p>
            We're here to assist you. Reach out to us anytime, and we'll happily
            answer your questions.
          </p>
          <div className="cmpy-info">
            <div className="icon">
              <i className="fas fa-map-marker-alt" />
            </div>
            <div className="cnt">
              <h5>Location</h5>
              <p>
                7, Newbold Street, Billborough, Nottingham: NG8 3DP,
                Nottinghamshire, England, United Kingdom
              </p>
            </div>
          </div>
          <div className="cmpy-info">
            <div className="icon">
              <i className="fas fa-phone-alt" />
            </div>
            <div className="cnt">
              <h5>Phone</h5>
              <a href="tel:07404950269">+44-74049 50269</a>
            </div>
          </div>
          <div className="cmpy-info">
            <div className="icon">
              <i className="far fa-envelope" />
            </div>
            <div className="cnt">
              <h5>Email</h5>
              <a href="tell:info@tirangainfotech.com">info@tirangainfotech.com</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidebarWidget;
