import React, { useState } from "react";
import call_center_contact from "../../../assets/img/Call-Center-contact.jpg";
import axios from "axios";

function ContactWrapper() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !subject || !message) {
      setErrorMessage("All fields are required.");
      return;
    }

    const serviceId = "service_kcrckk3";
    const templateId = "template_y5c1hyy";
    const publickey = "LmEXJVaZ52abndk_0";

    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publickey,
      template_params: {
        from_name: name,
        from_email: email,
        to_name: "tiranga infotech",
        subject: subject,
        message: message,
      },
    };

    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        data
      );
      console.log(res.data);
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setSuccessMessage("Thank you for contacting us. Your message has been received, and we will respond within 24-48 hours.");
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to send message. Please try again later.");
    }
  };

  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Get in touch</span>
                  <h2>Contact us if you have more questions.</h2>
                </div>
                <ul className="social-follow">
                  <li>
                    <a style={{ color: "white" }}>
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a style={{ color: "white" }}>
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a style={{ color: "white" }}>
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Location</h3>
                      <p>
                        7, Newbold Street, Billborough, Nottingham: NG8 3DP,
                        Nottinghamshire, England, United Kingdom.
                      </p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="tel:47404950269">+44-74049 50269</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="tell:info@example.com">
                        info@tirangainfotech.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.987257152373!2d-1.2210964654676162!3d52.971094484615534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879ebf82bc231dd%3A0x955a6287b0f40ff!2s7%20Newbold%20St%2C%20Nottingham%20NG8%203DP%2C%20UK!5e0!3m2!1sen!2sin!4v1715756357546!5m2!1sen!2sin"></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="getin-touch">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div
                  className="contact-form wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <h3>Let's Connect</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          onChange={(e) => setSubject(e.target.value)}
                          value={subject}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="message"
                          cols={30}
                          rows={10}
                          placeholder="Your message"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                          required
                        />
                        <input type="submit" />
                      </div>
                    </div>
                    {errorMessage && <div className="error-message" style={{ color: "red" }}>{errorMessage}</div>}
                    {successMessage && <div className="success-message" style={{ color: "green" }}>{successMessage}</div>}
                  </form>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow animate fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="call-banner">
                  <img
                    src={process.env.PUBLIC_URL + call_center_contact}
                    alt="images"
                    height={500}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactWrapper;
