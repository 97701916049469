import React from "react";
import aboutus_1 from "../../../assets/img/aboutus-1.jpg";
import aboutus_2 from "../../../assets/img/aboutus-2.jpg";
import aboutus_3 from "../../../assets/img/aboutus-3.jpg";

function WhyChooseUs() {
  return (
    <>
      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img
                    src={process.env.PUBLIC_URL + aboutus_1}
                    alt="why-choose images"
                  />
                </div>
                <div className="choose-banner2">
                  <img
                    src={process.env.PUBLIC_URL + aboutus_2}
                    alt="why-choose images"
                  />
                  <img
                    src={process.env.PUBLIC_URL + aboutus_3}
                    alt="why-choose images"
                  />
                </div>
                <div className="years">
                  <h5>5+</h5>
                  <span>years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose Us?</span>
                  <h2>We create ideas & policies for your company future</h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">150</span>
                    <sup>+</sup>
                    <h5>Projects Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">50</span>
                    <sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">20</span>
                    <sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>
                  Our commitment to innovation, excellence, and client
                  satisfaction has made us a trusted name in the IT industry. We
                  are driven by the desire to empower businesses by delivering
                  cutting-edge solutions tailored to their specific needs.
                  Whether you're a startup looking to establish your online
                  presence or an established enterprise seeking to optimize your
                  IT infrastructure, we have the expertise and passion to assist
                  you.
                </p>
                <div className="buttons-group">
                  <span style={{backgroundColor: "#e16a32",color: "#000"}}>24/7 Support</span>
                  <span style={{backgroundColor: "white",color: "#000"}}>Unique Design</span>
                  <span style={{backgroundColor: "green",color: "#000"}}>Clean Code Development</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
