import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Projects</span>
              <h2>Projects</h2>
              <p>
              At [Tiranga Infotech], we specialize in creating customized customer service strategies that makes use of advanced technology to keep our customers happy and loyal.
              </p>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
