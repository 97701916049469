import React from "react";
import { Link } from "react-router-dom";
import newpost_1 from "../../../assets/img/blog/newpost-1.jpg";
import newpost_2 from "../../../assets/img/blog/newpost-2.jpg";
import newpost_3 from "../../../assets/img/blog/newpost-3.jpg";

function NewPostWidget() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="sidebar-widget">
        <h4>Newest Post</h4>
        <div className="recent-post">
          <div className="recent-thumb">
            <Link>
              <img src={process.env.PUBLIC_URL + newpost_1} alt="catagoryImg" />
            </Link>
          </div>
          <div className="recent-post-cnt">
            <span>15.02.2023</span>
            <h5>
              <Link>Building Your Digital Presence: The Essentials of Website Development.</Link>
            </h5>
          </div>
        </div>
        <div className="recent-post">
          <div className="recent-thumb">
            <Link>
              <img src={process.env.PUBLIC_URL + newpost_2} alt="catagoryImg" />
            </Link>
          </div>
          <div className="recent-post-cnt">
            <span>30.10.2023</span>
            <h5>
              <Link>
                Crafting Seamless Experiences: A Deep Dive into UI/UX Design.
              </Link>
            </h5>
          </div>
        </div>
        <div className="recent-post">
          <div className="recent-thumb">
            <Link>
              <img src={process.env.PUBLIC_URL + newpost_3} alt="catagoryImg" />
            </Link>
          </div>
          <div className="recent-post-cnt">
            <span>20.01.2024</span>
            <h5>
              <Link>
                Navigating the Digital Landscape: A Comprehensive Guide to
                Digital Marketing.
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPostWidget;
