import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import reivewer from "../../../assets/img/reivewer.webp";
import reivewer_1 from "../../../assets/img/reivewer-1.webp";

import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);
function Testimonial() {
  const testimonialSlider = {
    loop: true,
    slidesPerView: 2,
    centeredSlides: true,
    duration: 5000,
    speed: 1000,
    autoplay: {
      delay: 3500,
      isableOnInteraction: true,
    },
    spaceBetween: 30,
    slideToClickedSlide: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 2,
      },
      1800: {
        slidesPerView: 2,
      },
    },
  };
  return (
    <>
      <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonials</span>
              <h2>Clients Say About Us</h2>
              <p>
                What our beloved clients have to say about us.
              </p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Vishal Rabadiya</h5>
                  <span>Mobile App Developer</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  Tiranga Infotech's IT expertise is unmatched. They patiently understood our unique needs and delivered solutions that have truly transformed our business. We whole heartedly recommend them.</p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + reivewer}
                      alt="testimonial-img"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Mansi Dani</h5>
                  <span>Shivam Infotel</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  We consider Tiranga Infotech a true partner in our success. Their dedication and proactive approach have been instrumental in helping us achieve our goals, and we look forward to a long-lasting relationship.</p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + reivewer_1}
                      alt="testimonial-img"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Ajay Mehta</h5>
                  <span>Full Stack Developer</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  Thanks to Bhavesh sir and Team, we have seen a significant improvement in our efficiency and productivity. His expertise has been a game-changer for our business.Tiranga Infotech has earned our complete trust. </p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + reivewer}
                      alt="testimonial-img"
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Neelanjona Thakur</h5>
                  <span>Singer</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  We are absolutely thrilled with the results we have seen since partnering with Tiranga Infotech. They are not just IT experts, they are problem solvers who go above and beyond to ensure our technology works for us, providing a really commendable support & assistance.</p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + reivewer_1}
                      alt="testimonial-img"
                    />
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default Testimonial;
