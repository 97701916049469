import React from 'react'
function ServiceDetailsItems() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Brainstroming &amp; Wirefarme</h4>
                <p>With creativity at the helm, we meticulously craft blueprints that translate concepts into tangible solutions. From initial sketches to detailed wireframes, we collaborate closely to ensure every aspect aligns with your vision and objectives. Our iterative approach ensures flexibility and adaptability as we refine designs to perfection. Experience the power of strategic planning and innovative thinking as we pave the way for your digital success. Let's turn ideas into reality, one wireframe at a time.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img src={process.env.PUBLIC_URL + '/img/blog/thumb-1.jpg'} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img src={process.env.PUBLIC_URL + '/img/blog/thumb-2.jpg'} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>UI Design</h4>
                <p>With a keen eye for detail and a focus on user-centric design, we craft interfaces that engage and delight. From sleek layouts to intuitive navigation, every element is meticulously designed to enhance usability and drive conversions. Our team combines creativity with usability principles to ensure that each design not only looks stunning but also delivers results. Explore the art of UI design with us and discover how we can elevate your brand and engage your audience like never before.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Final Developing</h4>
                <p>With precision and expertise, we transform designs into fully functional solutions that exceed expectations. Our development team employs cutting-edge technologies and best practices to ensure optimal performance and scalability. Through rigorous testing and refinement, we guarantee seamless functionality across all platforms and devices. Witness the culmination of our efforts as your vision materializes into a dynamic and user-friendly digital solution. Experience the power of transformative development with us and unlock endless possibilities for your business.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img src={process.env.PUBLIC_URL + '/img/blog/thumb-3.jpg'} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default ServiceDetailsItems