import research_work from "../../../assets/img/blog/blog-researchwork.jpg";
import uiux from "../../../assets/img/blog/blog-uiux.webp";
import software from "../../../assets/img/blog/blog-software.webp";
import security from "../../../assets/img/blog/blog-security.jpg";
import website from "../../../assets/img/blog/blog-website.jpg";
import mobileapp from "../../../assets/img/blog/blog-mobileapp.jpeg";
import digital from "../../../assets/img/blog/blog-digital.jpg";
import game from "../../../assets/img/blog/blog-game.webp";
import ar from "../../../assets/img/blog/blog-ar.jpg";
import it from "../../../assets/img/blog/blog-it.jpg";

import research_icon from "../../../assets/img/icons/research-work.webp";
import game_icon from "../../../assets/img/icons/game-development.webp";
import app_icon from "../../../assets/img/icons/mobile-app.webp";
import cyber_icon from "../../../assets/img/icons/cyber-security.webp";
import consultation_icon from "../../../assets/img/icons/consultation.webp";
import coding_icon from "../../../assets/img/icons/coding.webp";
import virtual_icon from "../../../assets/img/icons/virtual-reality.webp";
import social_icon from "../../../assets/img/icons/social-media.webp";
import ui_ux_icon from "../../../assets/img/icons/ui-ux.webp";
import software_icon from "../../../assets/img/icons/software.webp";

const service = [
  {
    num: 1,
    icon: research_icon,
    img: research_work,
    title: "Research Work & Consulting",
    sortdetails: `Research and analyze the latest trends and innovations in the IT industry, such as artificial intelligence, blockchain, cloud computing, and cybersecurity.`,
    paragraph1: `Research and analyze the latest trends and innovations in the IT
              industry, such as artificial intelligence (AI), blockchain, Internet
              of Things (IoT), cloud computing, and cybersecurity. Explore how these
              technologies are being adopted and leveraged by IT companies to drive
              growth and innovation.`,
    paragraph2: `Select several successful IT companies and conduct in-depth case
              studies on their business strategies, market positioning,
              products/services, and growth trajectory. Highlight key factors that
              contributed to their success and lessons that other IT companies can
              learn from their experiences.`,
    paragraph3: `Explore ethical dilemmas and considerations related to data privacy,
              artificial intelligence ethics, algorithmic bias, responsible AI
              development, and ethical use of technology. Discuss how IT companies
              can uphold ethical standards and promote responsible innovation in
              their operations.`,
  },
  {
    num: 2,
    icon: ui_ux_icon,
    img: uiux,
    title: "UI/UX Designing",
    sortdetails: `Delve into the fundamentals of UI/UX design, exploring the differences between UI and UX and their respective roles in creating exceptional digital products.`,
    paragraph1: `Delve into the fundamentals of UI/UX design, exploring the differences
              between UI and UX and their respective roles in creating exceptional
              digital products. Learn about the importance of user centered design
              principles, accessibility, and usability in crafting seamless
              experiences that delight users.`,
    paragraph2: `Explore the principles of visual design and their role in creating
              aesthetically pleasing and cohesive user interfaces. Dive into topics
              like typography, color theory, layout, and iconography, and discover
              how design elements work together to communicate information and evoke
              emotion.`,
    paragraph3: `Stay ahead of the curve with insights into the latest trends and
              innovations shaping UI/UX design. Explore topics like
              microinteractions, voice user interfaces (VUI), augmented reality
              (AR), and responsive design, and learn how designers are embracing new
              technologies and design patterns to create immersive and engaging
              experiences.`,
  },
  {
    num: 3,
    icon: software_icon,
    img: software,
    title: "Software Development",
    sortdetails: `Delve into the stages of the software development lifecycle, from initial planning and equirements gathering to coding, testing, deployment, and maintenance.`,
    paragraph1: ` Delve into the stages of the software development lifecycle,
              from initial planning and requirements gathering to coding,
              testing, deployment, and maintenance. Explore each phase's
              significance and learn how teams collaborate and iterate to
              deliver high quality software products.`,
    paragraph2: `After testing and debugging, the software is deployed or
              released to users. This may involve installing the software on
              servers, distributing it to end users, or making it available
              for download from app stores or websites.`,
    paragraph3: `Once the software is deployed, it requires ongoing maintenance
              and support to address any issues that arise, add new features
              or enhancements, and ensure it remains compatible with
              evolving technologies and platforms.`,
  },
  {
    num: 4,
    icon: app_icon,
    img: mobileapp,
    title: "   Mobile App Development",
    sortdetails: `Delve into the principles of user-centered design and their role in
              creating intuitive and engaging mobile app experiences. `,
    paragraph1: ` Mobile app development lifecycle, from ideation and planning to
              design, development, testing, and deployment. Learn about the roles
              and responsibilities of developers, designers, testers, and project
              managers at each stage of the process.`,
    paragraph2: ` Delve into the principles of user-centered design and their role in
              creating intuitive and engaging mobile app experiences. Learn about
              user research, persona development, wireframing, prototyping, and
              usability testing techniques that help developers understand user
              needs and preferences.`,
    paragraph3: ` Discover the cutting edge technologies and tools powering modern
              mobile app development. Explore topics like cloud computing,
              location-based services, augmented reality (AR), and machine learning,
              and learn how developers leverage these technologies to create
              innovative and feature-rich apps.`,
  },
  {
    num: 5,
    icon: game_icon,
    img: game,
    title: "Game Development",
    sortdetails: `Dive into the creative process behind game design, where concepts are
              born, worlds are imagined, and gameplay mechanics are crafted.`,
    paragraph1: ` Dive into the creative process behind game design, where concepts are
              born, worlds are imagined, and gameplay mechanics are crafted. Explore
              the principles of game design, from player engagement and storytelling
              to level design and character development, and discover the keys to
              creating memorable gaming experiences.`,
    paragraph2: ` Discover the cutting edge technologies powering modern game
                development, from game engines like Unity and Unreal Engine to
                graphics rendering techniques, artificial intelligence, and virtual
                reality. Explore how advancements in hardware and software have
                transformed the gaming landscape, enabling developers to push the
                boundaries of creativity and innovation.`,
    paragraph3: `  Examine the common challenges and obstacles faced by game developers,
              from technical constraints and resource limitations to tight deadlines
              and creative blocks. Learn about strategies for overcoming these
              challenges, including effective project management, collaboration, and
              iteration.`,
  },

  {
    num: 6,
    icon: cyber_icon,
    img: security,
    title: " Cybersecurity Solutions",
    sortdetails: `Discover the importance of cybersecurity solutions in safeguarding
              against cyber threats and mitigating risks. `,
    paragraph1: `Examine the landscape of cyber threats facing individuals,
              and governments today. From malware and phishing attacks to insider
              threats and DDoS attacks, explore the diverse range of cybersecurity
              challenges that organizations must navigate in an increasingly digital
              world.`,
    paragraph2: `Discover the importance of cybersecurity solutions in safeguarding
              against cyber threats and mitigating risks. Explore the wide array of
              technologies and strategies available, including firewalls, antivirus
              software, intrusion detection systems, encryption, and more, and learn
              how they work together to form a comprehensive defense posture.`,
    paragraph3: `Discover how advanced analytics and artificial intelligence (AI) are
              revolutionizing cybersecurity defense. Explore the use of machine
              learning algorithms to detect anomalous behavior, identify potential
              threats, and automate response actions, enhancing the effectiveness
              and efficiency of cybersecurity operations.`,
  },
  {
    num: 7,
    icon: coding_icon,
    img: website,
    title: "  Website Development",
    sortdetails: `Delve into the fundamentals of website development, exploring the
              processes involved in creating websites from conception to launch.`,
    paragraph1: ` Delve into the fundamentals of website development, exploring the
              processes involved in creating websites from conception to launch.
              Learn about the roles of web designers, developers, and content
              creators, and understand how they collaborate to bring websites to
              life.`,
    paragraph2: `Explore the critical role of user experience (UX) design in creating
              engaging and intuitive websites. Learn about user research,
              wireframing, prototyping, and usability testing techniques that help
              designers understand user needs and preferences and optimize the
              website's usability and effectiveness.`,
    paragraph3: ` Examine the importance of responsive design and mobile optimization in
              today's mobile-first world. Learn how to design websites that adapt
              seamlessly to different screen sizes and devices, providing a
              consistent and user-friendly experience across desktops, tablets, and
              smartphones.`,
  },
  {
    num: 8,
    icon: social_icon,
    img: digital,
    title: "Digital Marketing",
    sortdetails: `Delve into the fundamentals of digital marketing, exploring its
              definition, importance, and evolution in the modern business
              landscape.`,
    paragraph1: ` Delve into the fundamentals of digital marketing, exploring its
              definition, importance, and evolution in the modern business
              landscape. Learn about the various channels and tactics that fall
              under the umbrella of digital marketing, from search engine
              optimization (SEO) and social media marketing to email marketing,
              content marketing, and beyond.`,
    paragraph2: `Explore the critical role of data and analytics in digital marketing
              success. Learn how businesses leverage data driven insights to
              understand customer behavior, track key performance indicators (KPIs),
              and optimize marketing campaigns for maximum impact and ROI.`,
    paragraph3: ` Discover the power of social media marketing in building brand
              awareness, fostering customer engagement, and driving website traffic
              and conversions. Explore best practices for leveraging popular social
              media platforms like Facebook, Instagram, Twitter, LinkedIn, and
              TikTok to connect with your audience and achieve your marketing goals.`,
  },
  {
    num: 9,
    icon: virtual_icon,
    img: ar,
    title: "AR/VR/MR Development",
    sortdetails: `Delve into the definitions and distinctions between Augmented Reality
              (AR), Virtual Reality (VR), and Mixed Reality (MR).`,
    paragraph1: `  Delve into the definitions and distinctions between augmented reality
              (AR), virtual reality (VR), and mixed reality (MR). Explore how each
              technology creates immersive experiences for users and learn about the
              unique features and capabilities of AR, VR, and MR devices and
              platforms.`,
    paragraph2: ` Trace the evolution of AR/VR/MR technology from its early beginnings
              to its current state and beyond. Learn about key milestones,
              breakthroughs, and innovations that have propelled the industry
              forward, from early experiments in the 20th century to today's
              cutting-edge devices and applications.`,
    paragraph3: ` Stay ahead of the curve with insights into the future of AR/VR/MR
              technology and its potential impact on society and industry. Explore
              emerging trends like augmented reality glasses, volumetric capture,
              haptic feedback, and virtual collaboration, and learn how businesses
              and developers can capitalize on these opportunities to drive
              innovation and growth.`,
  },
  // {
  //   // id: 'it',
  //   num: 10,
  //   icon: consultation_icon,
  //   img: it,
  //   title: "  IT Consulting",
  //   sortdetails: `Explore the advantages that businesses can gain from engaging
  //   IT consultants, such as`,
  //   paragraph1: `Explore the advantages that businesses can gain from engaging IT
  //             consultants, such as improved efficiency, cost savings, enhanced
  //             security, scalability, and access to specialized expertise.`,
  //   paragraph2: ` Explore how IT consulting services vary across different industries,
  //             such as healthcare, finance, retail, manufacturing, education, and
  //             government. Discuss industry-specific challenges, regulations, and
  //             technology trends.`,
  //   paragraph3: `  Explore how IT consulting plays a crucial role in guiding businesses
  //             through digital transformation initiatives, including digital strategy
  //             development, legacy system modernization, customer experience
  //             optimization, and data-driven decision-making.`,
  // },
];

export default service;
