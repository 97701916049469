import React from "react";
import { Link } from "react-router-dom";
import data from "../../../data/data";
import { useLocation } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import brainstorming from "../../../assets/img/project/brainstorming.jpg"
import drawing from "../../../assets/img/project/drawing.jpg"
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
  };
  const location = useLocation();
  const index = new URLSearchParams(location.search).get("index");
  const projectItem = index !== null ? data[index] : null;

  if (!projectItem) {
    console.error(`Project item not found for index: ${index}`);
    return (
      <div className="project-details-content">
        <p>Project item not found</p>
      </div>
    );
  }
  
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img
            src={projectItem.detailImage}
            alt="mages"
          />
          <div className="tag">
            <Link
              to={`${process.env.PUBLIC_URL}/project-details`}
              onClick={window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              {projectItem.projectName}
            </Link>
          </div>
        </div>
        <h3>{projectItem.heading}</h3>
        <p>
        {projectItem.paragraph1}
        </p>
        <div className="clinet-need">
          <h4>{projectItem.heading2}</h4>
          <p>
          {projectItem.paragraph2}
          </p>
          <p>{projectItem.paragraph3}</p>
          <p>{projectItem.paragraph4}</p>
        </div>
        <div className="working-process">
          <h4>Working Process</h4>
          <Swiper {...projectSlider} className="swiper work-process-slide">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      brainstorming
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>01. Brainstorming</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      drawing                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>02. Drawing</span>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="arrow">
              <div className="swiper-button-next-c">
                <i className="bi bi-arrow-right" />
              </div>
              <div className="swiper-button-prev-c">
                <i className="bi bi-arrow-left" />
              </div>
            </div>
          </Swiper>
          <p>
            With creativity at the helm, we meticulously craft blueprints that
            translate concepts into tangible solutions. From initial sketches to
            detailed wireframes, we collaborate closely to ensure every aspect
            aligns with your vision and objectives. Our iterative approach
            ensures flexibility and adaptability as we refine designs to
            perfection. Experience the power of strategic planning and
            innovative thinking as we pave the way for your digital success.
            Let's turn ideas into reality, one wireframe at a time.
          </p>
        </div>
        <div className="check-lunch">
          <h4>Check &amp; Launch</h4>
          <div className="row">
            <div className="col-lg-6">
              <ul className="check">
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Needs Assessment
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Strategic Planning
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Custom Development
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Robust Infrastructure
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Quality Assurance
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Ongoing Support
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <p style={{whiteSpace: "break-spaces"}}>
                We're your strategic ally in navigating the digital landscape.
                With a focus on innovation and reliability, we deliver tailored
                solutions to propel your business forward.Our comprehensive
                suite of services covers everything from IT consulting and
                software development to cloud solutions and cybersecurity. We
                meticulously assess your needs, strategize the best approach,
                and execute with precision to ensure success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetailsContent;
