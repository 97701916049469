import React from "react";
import ServiceCard from "./ServiceCard";
import service from "../ServiceDetails/Service";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                We at Tiranga have a diverse range of services for our clients,
                where we operate in these core areas services.
              </p>
            </div>
          </div>
          <div className="row g-4">
            {service.map((item,index) => (
              <div
                key={item.id}
                class={`col-md-6 col-lg-4 wow animate fadeInUp`}
                data-wow-delay={`${200 + index * 100}ms`}
                data-wow-duration="1500ms"
              >
                <ServiceCard
                  serviceNumber={item.num}
                  image={item.icon}
                  title={item.title}
                  details={item.sortdetails}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
