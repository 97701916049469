import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
import research from "../../../assets/img/icons/research-work.webp";
import game from "../../../assets/img/icons/game-development.webp";
import app from "../../../assets/img/icons/mobile-app.webp";
import cyber from "../../../assets/img/icons/cyber-security.webp";
import consultation from "../../../assets/img/icons/consultation.webp";
import coding from "../../../assets/img/icons/coding.webp";
import virtual from "../../../assets/img/icons/virtual-reality.webp";
import social from "../../../assets/img/icons/social-media.webp";
import ui_ux from "../../../assets/img/icons/ui-ux.webp";
import software from "../../../assets/img/icons/software.webp";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Offerings</span>
              <h2>Service Areas</h2>
              <p>
                We at Tiranga have a diverse range of services for our clients,
                where we operate in these core areas services.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + research}
                      alt="research-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Research Work & Consulting</h4>
                  <p>
                  Research and analyze the latest innovations in the IT industry, such as artificial intelligence, cybersecurity.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=0`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + ui_ux}
                      alt="ui-ux-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>UI/UX Design</h4>
                  <p>
                  Learn about the importance of user centered design
              principles and accessibility in crafting seamless
              experiences that delight users.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=1`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + software}
                      alt="Software-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Software Development</h4>
                  <p>
                  Software development lifecycle, from initial planning and equirements gathering to coding, testing, deployment, and maintenance.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=2`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + app}
                      alt="Mobile-app-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Mobile Development</h4>
                  <p>
                  Delve into the principles of user-centered design and their role in
                  creating intuitive and engaging mobile app experiences.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=3`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>05</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + coding}
                      alt="coding-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Website Development</h4>
                  <p>
                  Delve into the fundamentals of website development, exploring the
                  processes involved in creating websites from conception to launch.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=6`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>06</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + virtual}
                      alt="virtual-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>AR/VR/MR Development</h4>
                  <p>
                  Delve into the definitions and distinctions between Augmented Reality
                  (AR), Virtual Reality (VR), and Mixed Reality (MR).
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=8`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>07</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + social}
                      alt="social-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Digital Marketing</h4>
                  <p>
                  Delve into the fundamentals of digital marketing, exploring its
              definition, importance, and evolution in the modern business
              landscape.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=7`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>09</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + game}
                      alt="game-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Game Development</h4>
                  <p>
                  Dive into the creative process behind game design, where concepts are
                  born, worlds are imagined, and gameplay mechanics are crafted.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=4`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>10</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + cyber}
                      alt="cyber-icon"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <h4>Cybersecurity Solutions</h4>
                  <p>
                  Discover the importance of cybersecurity solutions in safeguarding
                  against cyber threats and mitigating risks.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details?index=5`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
