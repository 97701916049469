import React from "react";
import widget_banner_bg from "../../../assets/img/widget-banner-bg.jpg";


function BannerWidget() {
  return (
    <>
      <div className="sidebar-banner">
        <img src={process.env.PUBLIC_URL + widget_banner_bg} alt="bannerImg" />
        <div className="banner-inner">
          <h3>
            Any Project <span>Call Now.</span>
            <img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="bannerImg" />
          </h3>
          <a href="tel:11231231234">+44-74049 50269</a>
        </div>
      </div>
    </>
  );
}

export default BannerWidget;
