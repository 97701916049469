import React from "react";
import { Link } from "react-router-dom";
import Logo_Tiranga from "../../../src/assets/img/logo.png";

function Footer(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <footer className={props.footerAddclass}>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
                      <img
                        src={process.env.PUBLIC_URL + Logo_Tiranga}
                        alt="FooterImg" 
                      />
                    </Link>
                  </div>
                  <p>
                  Ready to build something amazing? Join 50+ successful businesses who trust us to fuel their growth. Let's create a partnership that delivers results.
                  </p>
                  <ul className="social-media-icons">
                    <li>
                      <a style={{color:"white"}}>
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a style={{color:"white"}} href="https://www.instagram.com/tirangainfotech/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a style={{color:"white"}}>
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    {/* <li>
                      <Link
                        to={`/service-details/?index=0`}
                        onClick={scrollTop}
                      >
                        Research Work
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={`/service-details/?index=1`}
                        onClick={scrollTop}
                      >
                        UI/UX Designing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/service-details/?index=2`}
                        onClick={scrollTop}
                      >
                        Software Development
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to={`/service-details/?index=3`}
                        onClick={scrollTop}
                      >
                        Mobile App Development
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={`/service-details/?index=4`}
                        onClick={scrollTop}
                      >
                        Game Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/service-details/?index=5`}
                        onClick={scrollTop}
                      >
                        Cybersecurity Solutions
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to={`/service-details/?index=6`}
                        onClick={scrollTop}
                      >
                        Web Development
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={`/service-details/?index=7`}
                        onClick={scrollTop}
                      >
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/service-details/?index=8`}
                        onClick={scrollTop}
                      >
                        AR/VR/MR Development
                      </Link>
                    </li>
                  
                   
                   
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        onClick={scrollTop}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/service`}
                        onClick={scrollTop}
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/project`}
                        onClick={scrollTop}
                      >
                        Project
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/blog`}
                        onClick={scrollTop}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/contact`}
                        onClick={scrollTop}
                      >
                        Contact Us
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Pricing Plan</Link>
                    </li> */}
                    {/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/faq`} onClick={scrollTop}>FAQ</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:474049 50269">+44-74049 50269</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="tell:info@example.com">
                        info@tirangainfotech.com
                      </a>
                    </div>
                  </div>
                  <div className="address align-items-baseline">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>
                      7, Newbold Street, Billborough, Nottingham: NG8 3DP,
                      Nottinghamshire, England, United Kingdom.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                  &#169;Copyright 2024 <b>Tiranga Infotech</b>
                  {/* | Made With Love 
                     <a href="https://dbuggedprogrammers.com/">
                     &nbsp;D'Bugged Programmers
                    </a> */}
                    {/* <Link to={"https://www.google.com/"}>D'Bugged Programmers</Link> */}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/privacy-policy`} onClick={scrollTop}>Privacy Policy</Link>
                    
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/terms-condition`}onClick={scrollTop}>Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
